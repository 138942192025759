<template>
  <aCollapse
    class="travel-filters expandable mt-20 mb-10"
    activeKey="0"
    expandIconPosition="right"
  >
    <aCollapse-panel key="1">
      <template #header>
        <aIcon type="filter" class="mr-5" /> FILTRAR
      </template>
      <aRow class="fiter-collumns" :gutter="20">
        <aCol class="col1" :span="12">
          <aRow :gutter="20">
            <aCol :span="6">
              <div class="travel-input">
                <label for class="filled">ID do contrato</label>
                <a-input
                  placeholder="ID + Enter"
                  v-model="contract.filters.id"
                  @pressEnter="getContracts()"
                />
              </div>
            </aCol>

            <aCol :span="18">
              <div class="travel-input">
                <label for class="filled">Contratante </label>
                <a-auto-complete
                  :data-source="
                    customers.list.map(
                      ({
                        first_name,
                        last_name,
                        id,
                        person_type,
                        trading_name,
                      }) => ({
                        value: id,
                        text:
                          person_type === 'Pessoa Física'
                            ? `${id} - ${first_name} ${last_name}`
                            : `${id} - ${trading_name}`,
                      })
                    )
                  "
                  v-model="customers.filters.customerName"
                  style="width: 100%; height: 32px"
                  placeholder="Buscar contratantes..."
                  @change="onChangeSearchCustomers"
                  @select="customerSelected"
                />
              </div>
            </aCol>

            <aCol :span="24">
              <div class="travel-input">
                <label for class="filled">Período</label>
                <a-range-picker
                  v-model="contract.filters.period.selected"
                  :format="['DD/MM/YYYY']"
                  :value-format="'YYYY-MM-DD'"
                />
              </div>
            </aCol>

            <aCol :span="6">
              <div class="travel-input">
                <label for class="filled">Empresas</label>
                <a-select
                  class="travel-input"
                  placeholder="Selecione"
                  v-model="contract.filters.companies.selected"
                  show-search
                  allow-clear
                  style="width: 100%"
                  @change="onSelectCompany"
                >
                  <a-select-option
                    v-for="(item, index) of companies.list"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.trading_name }}
                  </a-select-option>
                </a-select>
              </div>
            </aCol>

            <aCol :span="18">
              <div class="travel-input">
                <label for class="filled">Filiais</label>

                <a-select
                  class="travel-input"
                  placeholder="Selecione"
                  mode="multiple"
                  v-model="contract.filters.companyBranches.selected"
                  show-search
                  style="width: 100%"
                  :disabled="
                    $store.state.userData.id == 46 ||
                    this.$store.state.userData.id == 45 ||
                    this.$store.state.userData.id == 44
                      ? true
                      : false
                  "
                >
                  <a-select-option
                    v-for="(item, index) of companyBranches.list"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.id }} - {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </aCol>

            <aCol :span="24">
              <div class="field payments">
                <span class="label">Status</span>
                <a-checkbox-group
                  style="width: 100%"
                  v-model="contract.filters.status.selected"
                  :disabled="contract.loading ? true : false"
                >
                  <a-checkbox
                    v-for="(item, index) of contract.filters.status.list"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-checkbox>
                </a-checkbox-group>
              </div></aCol
            >

            <aCol :span="24">
              <div class="field payments">
                <span class="label">Tipo de venda</span>

                <a-checkbox-group
                  style="width: 100%"
                  v-model="contract.filters.saleType.selected"
                  :disabled="contract.loading ? true : false"
                >
                  <a-checkbox
                    v-for="(item, index) of contract.filters.saleType.list"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-checkbox>
                </a-checkbox-group>
              </div>
            </aCol>

            <aCol :span="24">
              <div class="field payments">
                <span class="label">Categorias do Produto</span>
                <a-checkbox-group
                  style="width: 100%"
                  v-model="contract.filters.productsCategory.selected"
                  :disabled="
                    $store.state.userData.id == 46 ||
                    this.$store.state.userData.id == 45 ||
                    this.$store.state.userData.id == 44
                      ? true
                      : false
                  "
                >
                  <a-checkbox
                    v-for="(item, index) of contract.filters.productsCategory
                      .list"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-checkbox>
                </a-checkbox-group>
              </div></aCol
            >

            <aCol
              v-if="
                contract.filters.productsCategory.selected.includes('flight')
              "
              :span="24"
            >
              <div class="field payments">
                <span class="label">Tipo de Vôo</span>
                <a-checkbox-group
                  style="width: 100%"
                  v-model="contract.filters.flightType.selected"
                  :disabled="contract.loading ? true : false"
                >
                  <a-checkbox
                    v-for="(item, index) of contract.filters.flightType.list"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-checkbox>
                </a-checkbox-group>
              </div></aCol
            >
          </aRow>
        </aCol>

        <aCol :span="12">
          <aCol v-if="$root.isAdmin() || $root.isMilesManager()" :span="24">
            <div class="travel-input">
              <label for class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="contract.filters.users.selected"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="(item, index) of contract.filters.users.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="24">
            <div class="field payments">
              <span class="label">Métodos de Pagamento</span>
              <a-checkbox-group
                style="width: 100%"
                v-model="contract.filters.paymentMethods.selected"
                :disabled="contract.loading ? true : false"
              >
                <a-checkbox
                  v-for="(item, index) of contract.filters.paymentMethods.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
          </aCol>

          <aCol :span="24">
            <div class="travel-input">
              <label for class="filled">Valor da Reserva (R$)</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="contract.filters.price.min"
                />
                <a-input
                  style="
                    width: 10%;
                    border-left: 0;
                    pointer-events: none;
                    background-color: #fff;
                    border: 0;
                  "
                  placeholder="até"
                />
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="contract.filters.price.max"
                  @change="getContracts()"
                />
              </a-input-group>
            </div>
          </aCol>

          <aCol :span="24">
            <div class="field payments">
              <span class="label">Marketing</span>
              <a-checkbox-group
                style="width: 100%"
                v-model="contract.filters.marketing.selected"
                :disabled="contract.loading ? true : false"
              >
                <a-checkbox
                  v-for="(item, index) of contract.filters.marketing.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div></aCol
          >

          <aCol :span="24">
            <a-checkbox
              v-model="contract.filters.ticketed"
              style="font-size: 11px; font-weight: 500; position: relative"
            >
              Bilhetados
            </a-checkbox>
          </aCol>
        </aCol>
      </aRow>
      <a-divider class="mt-0 mb-15" />
      <aButton
        class="mb-0"
        type="primary"
        :loading="contract.loading"
        @click="getContracts()"
        ><aIcon type="search" /> Filtrar contratos</aButton
      >
    </aCollapse-panel>
  </aCollapse>
</template>

<script>
import _ from "lodash";
import customerMixins from "@/components/customers/mixins/customerMixins";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";
import companyMixins from "@/mixins/companies/companyMixins";

export default {
  name: "ContractListFilters",
  props: {
    contract: Object,
  },
  mixins: [customerMixins, companyBranchMixins, companyMixins],
  data() {
    return {};
  },
  mounted() {
    this.getCompanyBranches();
    this.getCompanies();
    this.getUsers();
  },
  methods: {
    onChangeSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.getCustomers();
      } else {
        this.customers.filters.searchTerm = "";
        this.contract.filters.customer = "";
      }
    }, 600),
    getContracts() {
      this.$emit("getContracts", this.contract);
    },
    customerSelected(customer) {
      console.log(customer);
      this.customers.filters.searchTerm = customer;
      this.contract.filters.customer = customer;
    },
    onSelectCompany(val) {
      this.companyBranches.filters.companyId = val;
      this.companyBranches.list = [];
      this.getCompanyBranches();
    },
    getUsers() {
      this.$http
        .get("/user/list?page=1&per_page=300&user_id=1")
        .then(({ data }) => {
          this.contract.filters.users.list = data.data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.fiter-collumns
  .col1
    padding-right: 20px !important
    border-right: 1px solid #ddd

.travel-filters
  .field.payments
    .ant-checkbox-wrapper
      width: 25%
      margin: 0 !important
      padding: 4px 11px 0px 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  .field
    position: relative
    padding-top: 10px
    label
      font-size: 11px
      color: #999
    .label
      position: absolute
      font-size: 10px
      font-weight: 600
      color: #434a53 !important
      top: -10px
      z-index: 1
</style>

<style lang="sass">
.payment-types
  span
    img
      margin: 0 5px 0 0
  span:last-child
    img
      margin: 0
.contract-details
  .ant-modal-close
    top: -48px
    padding: 0 !important
    height: 20px
    width: 20px
    color: #FFF !important
    right: 16px
  .ant-modal-body
    padding: 0
.ant-tooltip
  max-width: 600px !important
</style>
